<template>
    <div class="view-bookings" v-if="translates && translates[langUrl]">
         <nav v-if="isDesktop && translates.navigation" class="navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="userFeedbacks">{{ translates.navigation.button_route_myBookings[lang] }}</div>
        </nav>
        <h1 class="view-bookings__title">{{ translates[langUrl].pageTitle[lang] }}</h1>
        <div class="view-bookings__content">
            <div v-if="bookings.length < 1" class="view-bookings__content__empty" v-html="translates[langUrl].noBookings[lang]"></div>
            <template v-for="(booking, i) in bookings">
                <div
                    :key="'booking-' + booking.code + '-' + i"
                    class="view-bookings__booking"
                    :class="{'view-bookings__booking-canceled': booking.status === BOOKING_STATUS.CANCELED}">
                    <!-- <div class="view-bookings__booking__status">
                        {{ BOOKING_STATUS_TITLE[booking.status] }}
                    </div> -->
                    <div class="view-bookings__booking__carousel">
                        <div v-if="booking.trip && booking.trip.pictures && booking.trip.pictures.length !== 0" 
                            :style="`background-image: url(${ imageSrc(booking.trip.pictures[booking.trip.mainPictureIndex]) })`" 
                            class="view-bookings__booking__img"
                        ></div>
                        <div class="view-bookings__booking__carousel__button">
                            <div class="view-bookings__booking__carousel__button__text">{{ BOOKING_STATUS_TITLE[booking.status] }}</div>
                        </div>
                    </div>
                    <div class="view-bookings__booking__container">
                        <div class="view-bookings__booking__trip" v-if="booking.trip">
                            {{ booking.trip.name }}
                        </div>
                        <v-spacer />
                        <div class="view-bookings__booking__dates">
                            <div class="view-bookings__booking__dates__start">
                                <div class="view-bookings__booking__dates__day">{{ booking.start | moment('dddd') }}</div>
                                {{ booking.start | moment('DD MMM YYYY') }}</div>
                            <img src="../../assets/ArrowCircleRight.svg" />
                            <div class="view-bookings__booking__dates__finish">
                                <div class="view-bookings__booking__dates__day">{{ booking.finish | moment('dddd') }}</div>
                                {{ booking.finish | moment('DD MMM YYYY') }}</div>
                        </div>
                        <div class="view-bookings__booking__price">
                            <div class="view-bookings__booking__price__label">{{ translates[langUrl].price_full[lang] }}</div>
                            <div class="view-bookings__booking__price__value"><span v-html="priceFormated(cost(booking), booking.currency)"></span> </div>
                        </div>
                        <div class="view-bookings__booking__price">
                            <div class="view-bookings__booking__price__label">{{ translates[langUrl].price_prepay[lang] }}</div>
                            <div class="view-bookings__booking__price__value"><span v-html="priceFormated(alreadyPaid(booking), booking.currency)"></span></div>
                            <!-- (<div class="view-bookings__booking__price__value"><span v-html="percent(booking.deposit,booking.price)"></span></div>%) -->
                        </div>
                        <div class="view-bookings__booking__price">
                            <div class="view-bookings__booking__price__label">{{ translates[langUrl].price_lastpay[lang] }}</div>
                            <div class="view-bookings__booking__price__value"><span v-html="priceFormated(needToPay(booking), booking.currency)"></span></div>
                            <!-- (<div class="view-bookings__booking__price__value"><span v-html="percent(needToPay(booking),booking.price)"></span></div>%) -->
                        </div>
                        <div class="view-bookings__booking__info">
                            {{ translates[langUrl].bookingCreate[lang] }}:
                            {{ booking.createdAt | moment('DD MMM YYYY') }}</div>
                        <BaseButton
                            v-if="needToPay(booking) > 0"
                            class="button-fill"
                            style="width: 100%"
                            :disabled="!isArrivalActive(booking)"
                            @click="payBooking(booking)">
                            {{ translates[langUrl].button_payBooking[lang] }}
                            (<span v-html="priceFormated(needToPay(booking), booking.currency)"></span>)
                        </BaseButton>
                        <div
                            v-if="needToPay(booking) <= 0"
                            class="view-bookings__booking__paid">{{ translates[langUrl].status_PAID[lang] }}</div>
                        <BaseButton
                            style="width: 100%; margin-top: 10px;"
                            @click="showPopupDetail(booking)"
                        >
                        {{ translates[langUrl].button_showMore[lang] }}
                        </BaseButton>
                    </div>
                </div>
            </template>
        </div>
        <v-dialog
            v-model="popupDetail"
            max-width="800px"
        >
            <div class="view-bookings__personal-data" v-if="activeBooking">
                <div class="view-bookings__personal-data__title">{{ translates[langUrl].popup_title[lang] }}</div>
                <div class="view-bookings__personal-data__close" @click="closePopupDetail"></div>
                <div class="view-bookings__personal-data__text">
                    {{ translates[langUrl].popup_tourTitle[lang] }} 
                    <span class="view-bookings__personal-data__text__accent"
                        v-if="activeBooking.trip"
                    ><a @click="open(activeBooking.trip.code)">{{ activeBooking.trip.name }}</a></span>
                </div>
                <div class="view-bookings__personal-data__text">
                    {{ translates[langUrl].popup_tourStart[lang] }} 
                    <span class="view-bookings__personal-data__text__accent">{{ activeBooking.start | moment('DD MMM YYYY') }}({{ activeBooking.start | moment('dddd') }})</span>
                </div>
                <div class="view-bookings__personal-data__text">
                    {{ translates[langUrl].popup_places[lang] }} 
                    <span class="view-bookings__personal-data__text__accent">{{ activeBooking.places }}</span>
                </div>
                <br>
                <div class="view-bookings__personal-data__title">{{ translates[langUrl].popup_mainTravelers[lang] }}</div>
                <div class="view-bookings__personal-data__text">
                    {{ translates[langUrl].popup_travelerName[lang] }} 
                    <span class="view-bookings__personal-data__text__accent">{{ activeBooking.mainTraveler.firstname }} {{ activeBooking.mainTraveler.lastname }}</span>
                </div>
                <div class="view-bookings__personal-data__text">
                    {{ translates[langUrl].popup_travelerBirthdate[lang] }} 
                    <span class="view-bookings__personal-data__text__accent">{{ activeBooking.mainTraveler.birthday | moment('DD MMM YYYY')}}</span>
                </div>
                <div class="view-bookings__personal-data__text">
                    {{ translates[langUrl].popup_travelerPhone[lang] }}
                    <span class="view-bookings__personal-data__text__accent">{{ activeBooking.mainTraveler.phone }}</span>
                </div>
                <div class="view-bookings__personal-data__text">
                    {{ translates[langUrl].popup_travelerMail[lang] }} 
                    <span class="view-bookings__personal-data__text__accent">{{ activeBooking.mainTraveler.mail }}</span>
                </div>
                <template v-for="(treveler, index) in activeBooking.additionalTravelers">
                    <div :key="'treveler-' + activeBooking.code + '-' + index">
                        <div class="view-bookings__personal-data__title">{{ translates[langUrl].popup_additionalTraveler[lang] }} {{ index + 2 }}:</div>
                        <div class="view-bookings__personal-data__text">
                            {{ translates[langUrl].popup_travelerName[lang] }} 
                            <span class="view-bookings__personal-data__text__accent">{{ treveler.firstname }} {{ treveler.lastname }}</span>
                        </div>
                        <div class="view-bookings__personal-data__text">
                            {{ translates[langUrl].popup_travelerBirthdate[lang] }}  
                            <span class="view-bookings__personal-data__text__accent">{{ treveler.birthday | moment('DD MMM YYYY')}}</span>
                        </div>
                        <!-- <div class="view-bookings__personal-data__text">
                            {{ translates[langUrl].popup_travelerPhone[lang] }} 
                            <span class="view-bookings__personal-data__text__accent">{{ activeBooking.mainTraveler.phone }}</span>
                        </div>
                        <div class="view-bookings__personal-data__text">
                            {{ translates[langUrl].popup_travelerMail[lang] }} 
                            <span class="view-bookings__personal-data__text__accent">{{ activeBooking.mainTraveler.mail }}</span>
                        </div> -->
                    </div>
                </template>
            </div>
        </v-dialog>

        <StripePaymentPopup
            v-model="stripePaymentPopup"
            :clientSecret="payment.clientSecret"
            width="600px" 
            :info="paymentInfo"
            />
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import moment from '@/plugins/moment';
    import BaseButton from '../../components/common/BaseButton';
    import { priceFormated, isMobile, imageSrc } from '@/helpers';
    import { BOOKING_STATUS, BOOKING_STATUS_TITLE, PAYMENT_STATUS } from '@/vars';
    import StripePaymentPopup from '../../components/common/StripePaymentPopup.vue';

    export default {
        name: 'Bookings',
        metaInfo() {
            return {
                title: this.translates[this.langUrl]? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton,
            StripePaymentPopup
        },
        data: () => ({
            BOOKING_STATUS,
            BOOKING_STATUS_TITLE,
            PAYMENT_STATUS,
            priceFormated,
            isDesktop: false,
            popupDetail: false,
            activeBooking: null,
            imageSrc,
            stripePaymentPopup: false,
            langUrl: '/user/bookings'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('bookings', {
                bookings: state => state.entities
            }),
            ...mapState('payments', {
                payment: state => state.entity
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            paymentInfo() {
                if(this.activeBooking) {
                    return {
                        start: this.activeBooking.start,
                        finish: this.activeBooking.finish,
                        name: this.activeBooking.name,
                        places: this.activeBooking.places,
                        amount: this.needToPay(this.activeBooking),
                        currency: this.activeBooking.currency,
                    }
                }
            },
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            if(this.$route.query.redirect_status === 'succeeded' && this.$route.query.payment_intent_client_secret) {
                const bookingId = await store.dispatch('payments/intentSucceded', { clientSecret: this.$route.query.payment_intent_client_secret });
                if (bookingId) {
                    await store.dispatch('bookings/pay', { id: bookingId });
                    await this.$router.push({ name: 'user-bookings' });
                }
            }

            store.commit('payments/CLEAR_ENTITY');
            store.commit('bookings/CLEAR_ENTITY');
            await this.getBookings();
            this.onResize();
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async getBookings() {
                await store.dispatch('bookings/fetch', {});
            },
            async open(code) {
                await this.$router.push({ name: 'trip', params: { code } });
            },
            price(booking) {
                if(booking && booking.discount) {
                    return Math.round(booking.price * (1 - (booking.discount / 100)))
                }
                return booking.price;
            },
            cost(booking) {
                return (this.price(booking) * booking.places)
            },
            alreadyPaid(booking) {
                return booking.payments.reduce((sum, payment) => {
                    return sum + (payment.status === PAYMENT_STATUS.SUCCESS ? payment.amount : 0);
                }, 0);
            },
            needToPay(booking) {
                if(booking.payments && booking.payments.length) {
                    const paid = booking.payments.reduce((sum, payment) => {
                        return sum + (payment.status === PAYMENT_STATUS.SUCCESS ? payment.amount : 0);
                    }, 0);
                    return this.cost(booking) - paid;
                }
                return booking.deposit * booking.places;
            },
            percent(x, y) {
                return x / y * 100;
            },
            isArrivalActive(booking) {
                return moment(booking.finish).isAfter(moment().format());
            },
            async payBooking(booking) {
                this.activeBooking = booking;
                await store.commit('payments/SET_ENTITY', {
                    trip: booking.trip,
                    booking: booking._id,
                    amount: this.needToPay(booking),
                });
                await store.dispatch('payments/save');

                if (process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
                    this.stripePaymentPopup = true;
                } else {
                    const widget = new cp.CloudPayments();
                    widget.pay('auth', // или 'charge'
                        { //options
                            publicId: process.env.VUE_APP_CLOUDPAYMENTS_PUBLIC_ID,
                            description: 'Оплата бронирования',
                            amount: +this.prepayment(),
                            currency: this.booking.currency,
                            invoiceId: this.booking._id,
                            skin: "mini",
                            data: {
                                trip: this.trip._id,
                                booking: this.booking._id,
                            }
                        },
                        {
                            onSuccess: async (options) => {
                                await store.commit('payments/SET_ENTITY', {
                                    ...this.payment,
                                    ...{
                                        status: PAYMENT_STATUS.SUCCESS,
                                        options
                                    }
                                });
                                await store.dispatch('payments/save');
                                await store.commit('payments/CLEAR_ENTITY');

                                await store.dispatch('bookings/pay', { id: this.booking._id });
                                
                                await this.$router.push({ name: 'user-bookings' });
                            },
                            onFail: async (reason, options) => {
                                await store.commit('payments/SET_ENTITY', {
                                    ...this.payment,
                                    ...{
                                        status: PAYMENT_STATUS.FAIL,
                                        reason,
                                        options
                                    }
                                });
                                await store.dispatch('payments/save');
                                await store.commit('payments/CLEAR_ENTITY');
                                console.log('pay onFail', options);
                            }
                        }
                    );
                }
            },
            async deleteBooking(booking) {
                await store.dispatch('bookings/delete', { id: booking._id });
                await store.dispatch('bookings/fetch', {});
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async userFeedbacks () {
                await this.$router.push({ name: 'user-bookings' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
            showPopupDetail(booking) {
                this.activeBooking = booking
                this.popupDetail = !this.popupDetail
            },
            closePopupDetail() {
                this.popupDetail = !this.popupDetail
            }
        },
    }
</script>

<style lang="scss">
    .view-bookings {
        @media all and (min-width: 768px) {
            padding: 0 32px;
        }
        @media all and (min-width:1464px) {
            padding: 0;
        }
        &__menu{
            margin: 13px 24px 0;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #44414D;
            @media all and (min-width:768px) {
                margin: 13px 0 0;
            }
        }
        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #3A4256;
            margin: 32px 24px 12px;
            @media all and (min-width:768px) {
            font-weight: bold;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin: 40px 0 36px;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            
            @media all and (min-width: 768px) {
                flex-direction: row;
                margin-right: -32px;
            }

            &__empty {
                font-weight: bold;
                font-size: 18px;
                line-height: 140%;
                color: #3A4256;
                text-align: center;
                margin: 10vw auto;
                max-width: 800px;
            }
        }
        &__booking{
            display: flex;
            flex-direction: column;
            padding: 0 0 24px;
            box-shadow: 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 32px;
            color: #3A4256;

            @media all and (min-width: 768px) {
                width: 445px;
                margin-right: 32px;
            }

            &__carousel {
                position: relative;
                width: 100%;
                height: 280px;
                

                &__button {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 6.5px 8px;
                    background: #FFFFFF;
                    border-radius: 158px;
                    cursor: pointer;
                    z-index: 100;

                    &__icon {
                        width: 16px;
                        height: 16px;
                        // margin-right: 4px;
                        // background-color: #FFFFFF;
                    }

                    &__text {
                        font-size: 12px;
                        line-height: 140%;
                        letter-spacing: -0.02em;
                        color: #E61E4D;
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                        margin: 0px 4px;
                        &.button-fill {
                            color: #FFFFFF;
                        }
                    }
                    &.button-fill {
                        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
                    }
                }

                &__label {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 5px 10px;
                    position: absolute;
                    left: 20px;
                    top: 269px;
                    background-color: #FFCD1D;
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 140%;
                    /* identical to box height, or 17px */
                    letter-spacing: -0.015em;
                    color: #FFFFFF;

                    &.second {
                        left: 120px;
                        top: 269px;
                        background: #48A7FF;
                    }
                }
            }

            &__img {
                height: 100%;
                width: 100%;
                background-size: cover;
            }

            &__container {
                padding: 16px 24px 0;
            }

            &__status {
                display: block;
                width: 100%;
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.015em;
                color: #FFFFFF;
                background: #48A7FF;
                text-align: center;
                margin-bottom: 10px;
            }
            
            &-canceled {
                background: #dddddd;
            }
            &__canceled {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-radius: 12px !important;
                border: 1px solid #3A4256 !important;
                margin-bottom: 5px;
                height: 48px;
                align-self: center;
                background: none;
                font-size: 14px;
                line-height: 140%;
            }
            &__paid {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-radius: 12px !important;
                border: 1px solid #48A7FF !important;
                color: #48A7FF;
                // margin-bottom: 5px;
                height: 48px;
                align-self: center;
                background: none;
                font-size: 14px;
                line-height: 140%;
            }

            &__trip {
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.02em;
                margin-bottom: 14px;
            }
            &__user {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.02em;
                margin-bottom: 14px;
            }
            &__dates {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.02em;
                padding: 16px 0;
                border-top: 1px solid #F6F7F9;
                border-bottom: 1px solid #F6F7F9;
                &__day {
                    font-size: 14px;
                    line-height: 140%;
                    color: #9FA5AD;
                }
            }
            
            &__price {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.02em;
                margin: 24px 0;
                &__label {
                    font-size: 18px;
                    letter-spacing: -0.02em;
                }
            }
            &__info {
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #9FA5AD;
                margin: 0 0 12px;
            }
        }
        &__personal-data {
            position: relative;
            max-height: 800px;
            width: 100%;
            padding: 30px 40px;
            background-color: #fff;
            font-family: 'Inter';
            font-style: normal;
            display: flex;
            flex-direction: column;
            &__close {
                position: absolute;
                top: 25px;
                right: 25px;
                width: 30px;
                height: 30px;
                background: url(../../assets/PlusGrey.svg);
                background-size: contain;
                transform: rotate(45deg);
                cursor: pointer;
            }
            &__title {
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
                color: #000000;
                margin: 0 auto 12px;
                text-align: center;
            }
            &__text {
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.02em;
                color: #A9AEB2;
                margin: 0 auto 5px;
                width: 100%;
                text-align: left;
                &__accent {
                    font-weight: 500;
                    color: black;
                }
            }
        }
    }
</style>